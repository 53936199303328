import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ArrowRight, Link } from 'shared';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    ctaBlock: {
        cursor: 'pointer',
        position: 'relative',
        display: 'block',
        flexGrow: 0,
        flexShrink: 1,
        flexBasis: `calc(100% / 4 - ${theme.spacing(4.25)}px)`,
        minHeight: '232px',
        backgroundColor: theme.palette.background.secondary,
        borderRadius: theme.palette.shape.borderRadius,
        color: 'currentColor',
        textDecoration: 'none',
        borderBottom: '0px solid transparent',
        transition: 'border-bottom 200ms ease',
        [theme.breakpoints.up('md')]: {
            '&:hover': {
                borderBottom: `6px solid ${theme.palette.background.primary}`,
                '& $ctaBlockText': {
                    opacity: 1,
                    visibility: 'visible',
                    '& + $ctaBlockTitle': {
                        opacity: 0,
                        visibility: 'hidden'
                    }
                }
            }
        }
    },
    small: {
        minHeight: '100px',
        borderRadius: '22px',
        '& $ctaBlockInner': {
            padding: `${theme.spacing(3.75)}px`
        },
        '& $ctaBlockTitle': {
            bottom: 'auto',
            top: '50%',
            transform: 'translateY(-50%)',
            paddingRight: '75px'
        }
    },
    ctaBlockInner: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-end',
        padding: `${theme.spacing(5.75)}px ${theme.spacing(3.75)}px`,
        height: '100%'
    },
    ctaBlockTitle: {
        position: 'absolute',
        bottom: `${theme.spacing(5.75)}px`,
        transition: 'opacity 200ms ease, visibility 200ms ease',
        fontWeight: '500'
    },
    ctaBlockText: {
        opacity: 0,
        visibility: 'hidden',
        transition: 'opacity 200ms ease, visibility 200ms ease'
    },
    disabled: {
        opacity: '.4',
        cursor: 'default',
        [theme.breakpoints.up('md')]: {
            '&:hover': {
                borderBottom: 0,
                '& $ctaBlockText': {
                    opacity: 0,
                    visibility: 'hidden',
                    '& + $ctaBlockTitle': {
                        opacity: 1,
                        visibility: 'visible'
                    }
                }
            }
        }
    },
    arrow: {
        position: 'absolute',
        right: '40px',
        top: '50%',
        transform: 'translateY(-50%)',
        height: '24px',
        color: theme.palette.text.secondary
    },
    primary: {
        backgroundColor: theme.palette.background.secondary,
        [theme.breakpoints.up('md')]: {
            '&:hover': {
                borderColor: theme.palette.background.secondaryContrast
            }
        }
    },
    secondary: {
        backgroundColor: theme.palette.background.blue,
        [theme.breakpoints.up('md')]: {
            '&:hover': {
                borderColor: theme.palette.background.blueContrast
            }
        }
    },
    tertiary: {
        backgroundColor: theme.palette.background.pink,
        [theme.breakpoints.up('md')]: {
            '&:hover': {
                borderColor: theme.palette.background.pinkContrast
            }
        }
    },
    quaternary: {
        backgroundColor: theme.palette.background.yellow,
        [theme.breakpoints.up('md')]: {
            '&:hover': {
                borderColor: theme.palette.background.yellowContrast
            }
        }
    },
    purple: {
        backgroundColor: theme.palette.background.purple,
        [theme.breakpoints.up('md')]: {
            '&:hover': {
                borderColor: theme.palette.background.purpleContrast
            }
        }
    },
    grey: {
        backgroundColor: theme.palette.background.grey,
        [theme.breakpoints.up('md')]: {
            '&:hover': {
                borderColor: theme.palette.background.greyContrast
            }
        }
    }
}));

const CtaBlock = (props) => {
    const {
        to = '',
        color = 'primary',
        hoverText = '',
        text = '',
        size = 'default',
        className,
        disabled = false,
        blank
    } = props;
    const classes = useStyles();

    const handleClick = () => {
        if (props.onClick && !disabled) props.onClick();
    };

    return (
        <Link
            to={to}
            target={blank ? '_blank' : '_self'}
            className={classNames(classes.ctaBlock, classes[color], classes[size], className, {
                [classes.disabled]: disabled
            })}
            onClick={handleClick}
        >
            <div className={classes.ctaBlockInner}>
                {hoverText && (
                    <Typography variant="body2" className={classes.ctaBlockText}>
                        {hoverText}
                    </Typography>
                )}
                <Typography variant="h6" className={classes.ctaBlockTitle}>
                    {text}
                </Typography>
            </div>
            {size === 'small' && (
                <div className={classes.arrow}>
                    <ArrowRight color="inherit" />
                </div>
            )}
        </Link>
    );
};

export default CtaBlock;
