import React, { useEffect, useState } from 'react';
import { VideoPlayer } from 'shared';

const ImageOrVideo = (props) => {
    const { url = '', type = '' } = props;

    const [realType, setRealType] = useState('IMAGE');

    useEffect(() => {
        if (type.includes('image')) {
            setRealType('IMAGE');
        }
        if (type.includes('video')) {
            setRealType('VIDEO');
        }
        if (type.includes('youtube')) {
            setRealType('YOUTUBE');
        }
    }, [type]);

    return (
        <React.Fragment>
            {realType === 'IMAGE' && <img src={url} alt="" />}
            {realType === 'YOUTUBE' && <VideoPlayer url={url} />}
        </React.Fragment>
    );
};

export default ImageOrVideo;
