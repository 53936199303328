import React from 'react';
import { ArrowRightAlt } from '@material-ui/icons';
import { convertToSlug } from 'helpers';
import { Grid, Typography, TableRow, TableCell } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Table, SwipeWrapper } from 'shared';
import { makeStyles } from '@material-ui/styles';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { GET_MY_USERS } from '../queries';
import { LoaderModal } from 'shared';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '1300px',
        //minWidth: '968px',
        width: '100%',
        margin: '0 auto',
        paddingBottom: `${theme.spacing(4)}px`,
        [theme.breakpoints.up('sm')]: {
            paddingBottom: `${theme.spacing(22)}px`
        }
    },
    title: {
        width: '100%',
        marginTop: `${theme.spacing(7)}px`,
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2)
        }
    },
    headerBar: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between'
    },
    table: {
        minWidth: '1200px'
    },
    tableContainer: {
        overflow: 'auto',
        paddingBottom: `${theme.spacing(15)}px`,
        [theme.breakpoints.down(580)]: {
            paddingTop: `0 !important`
        }
    },
    actionButton: {
        border: '1px solid ' + theme.palette.borderColor.secondary,
        borderRadius: '50%',
        color: theme.palette.primary.main,
        background: theme.palette.common.white,
        width: '45px',
        height: '45px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '5px',
        marginLeft: '20px',
        cursor: 'pointer',
        transition: 'all 300ms ease',
        '& .MuiSvgIcon-root': {
            width: '25px',
            height: '25px'
        },
        '&:hover': {
            border: '1px solid ' + theme.palette.primary.main,
            background: theme.palette.primary.main,
            color: theme.palette.common.white
        }
    },
    colTitle: {
        fontWeight: 500
    }
}));

const MyCandidates = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();

    //queries
    const { loading, data } = useQuery(GET_MY_USERS);

    const likes = data && data.currentUser ? data.currentUser.likes : [];

    return (
        <Grid container className={classes.root} spacing={8} justify="space-between">
            <Grid item xs={12} className={classes.headerBar}>
                <Typography className={classes.title} variant="h1">
                    {t('candidates.myCandidates')}
                </Typography>
            </Grid>
            <Grid item xs={12} className={classes.tableContainer}>
                {likes && likes.length > 0 ? (
                    <SwipeWrapper>
                        <Table
                            head={['Naam', 'Regio', 'Woonplaats', 'Functieschaal', 'Corporatie', 'Uren per week', '']}
                            className={classes.table}
                            renderRows={() =>
                                likes &&
                                likes.map((like) => (
                                    <TableRow key={like.id}>
                                        <TableCell colSpan={1}>
                                            <Typography className={classes.colTitle}>
                                                {like.profile ? like.profile.fullName || '' : ''}
                                            </Typography>
                                        </TableCell>
                                        <TableCell colSpan={1}>
                                            <Typography>
                                                {like.profile.region ? like.profile.region.description || '' : ''}
                                            </Typography>
                                        </TableCell>
                                        <TableCell colSpan={1}>
                                            <Typography>
                                                {like.profile.city ? like.profile.city.name || '' : ''}
                                            </Typography>
                                        </TableCell>
                                        <TableCell colSpan={1}>
                                            <Typography>
                                                {like.profile.educationGrade
                                                    ? like.profile.educationGrade.description || ''
                                                    : ''}
                                            </Typography>
                                        </TableCell>
                                        <TableCell colSpan={1}>
                                            <Typography>{like.corporation ? like.corporation.name : ''}</Typography>
                                        </TableCell>
                                        <TableCell colSpan={1}>
                                            <Typography>
                                                {like.profile.workingHoursPerWeek
                                                    ? like.profile.workingHoursPerWeek.value || 'Onbekend'
                                                    : 'Onbekend'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <div className={classes.center}>
                                                <Link
                                                    to={`/kandidaten/${like.id}/${convertToSlug(
                                                        `${like.profile.fullName}`
                                                    )}`}
                                                    className={classes.actionButton}
                                                >
                                                    <ArrowRightAlt />
                                                </Link>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        />
                    </SwipeWrapper>
                ) : (
                    <Typography>Geen kandidaten gevonden</Typography>
                )}
            </Grid>
            <LoaderModal loading={loading} />
        </Grid>
    );
};

export default MyCandidates;
