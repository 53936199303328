import { LOGIN, SET_USER, SET_TOKEN, LOGOUT, SET_ROLES, SET_ROLE, SET_USER_ID } from './authActions';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

let token = Cookies.get('jwt_flow');
const haveToken = token && token !== 'deleted';

let userId = null;
let roles = [];
let decodeToken;

if (haveToken) {
    try {
        decodeToken = jwtDecode(token);
        userId = decodeToken.id;
        roles = decodeToken.roles;
    } catch (error) {
        decodeToken = null;
    }
}

export default (
    state = {
        token: haveToken ? token : '',
        isLoggedIn: haveToken ? true : false,
        userId: userId,
        user: {},
        roles: roles,
        role: ''
    },
    action
) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                isLoggedIn: action.payload
            };
        case LOGOUT:
            return {
                ...state,
                token: undefined,
                user: {},
                userId: null,
                isLoggedIn: false,
                roles: []
            };
        case SET_USER:
            return {
                ...state,
                user: action.payload
            };
        case SET_TOKEN:
            return {
                ...state,
                token: action.payload
            };
        case SET_ROLES:
            return {
                ...state,
                roles: action.payload
            };
        case SET_ROLE:
            return {
                ...state,
                role: action.payload
            };
        case SET_USER_ID:
            return {
                ...state,
                userId: action.payload
            };
        default:
            return state;
    }
};
