import React, { useReducer, useEffect } from 'react';
import { useStyles } from '../styles';
import { useTranslation } from 'react-i18next';
import { useUploadFile } from 'hooks';
import { Grid, Typography, Paper } from '@material-ui/core';
import { FileUpload, Link } from 'shared';
import { UPLOAD_DOCUMENT, DELETE_DOCUMENT } from '../queries';
import ClearIcon from '@material-ui/icons/Clear';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

const FilesBlock = (props) => {
    const { form, currentCareerProfile = {}, refetch } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const [removeDocument] = useMutation(DELETE_DOCUMENT);
    const { enqueueSnackbar } = useSnackbar();

    //hooks
    const [uploadCv] = useUploadFile();
    const [uploadFile, filesObj] = useUploadFile(UPLOAD_DOCUMENT);

    //reducers
    const fileReducer = (state = [], data) => {
        let temp = [...state];

        if (data.type === 'add') {
            if (!temp.some((i) => i.name === data.value.name)) {
                temp.push(data.value);
            }
        }

        return temp;
    };
    const [files, setFiles] = useReducer(fileReducer, []);

    useEffect(() => {
        refetch();
    }, [filesObj, refetch]);

    //functions
    const handleCV = (file) => {
        uploadCv(file, 'cvId', form);
    };

    const handleMulti = (data) => {
        data.forEach((file) => {
            if (!files.some((i) => i.name === file.name)) {
                uploadFile(file);
            }
            setFiles({
                type: 'add',
                value: file
            });
        });
    };

    const clearFile = (file) => () => {
        removeDocument({
            variables: {
                id: file.id
            }
        })
            .then((resp) => {
                refetch();
                if (resp.errors) {
                    enqueueSnackbar({
                        variant: 'warning',
                        message: t('Dit bestand mag niet verwijderd worden')
                    });
                }
            })
            .catch((error) => {
                console.warn('handleDelete error: ', error);
            });
    };

    return (
        <Grid item xs={12}>
            <Paper className={classes.paper} elevation={0}>
                <Typography className={classes.heading}>{t('userProfile.titles.files')}</Typography>
                <Typography className={classes.uploadLabel}>Upload CV:</Typography>
                <FileUpload
                    label="CV uploaden"
                    classes={{ root: classes.fileUpload }}
                    onSucces={handleCV}
                    maxFileSize={false}
                    accept="application/pdf"
                    noLabel={false}
                    initialValue={currentCareerProfile.cv || null}
                    name="cvId"
                    form={form}
                    noFormChance={true}
                />

                <Typography className={classes.uploadLabel}>
                    Huidige bestanden:{' '}
                    <Link target="_blank" to={`${process.env.REACT_APP_MY_FLOW_DOMAIN}/mijn-documenten`}>
                        <span className={classes.linkText}>( Bestanden beheren )</span>
                    </Link>
                </Typography>
                {currentCareerProfile.documents
                    ? currentCareerProfile.documents.map((doc) => (
                          <div key={doc.id} className={classes.files}>
                              <Link target="_blank" to={doc.url}>
                                  {doc.name}
                              </Link>{' '}
                              <ClearIcon onClick={clearFile(doc)} className={classes.clearIcon} />
                          </div>
                      ))
                    : 'Nog geen bestanden'}
                <FileUpload
                    label="Bestanden uploaden"
                    onSucces={handleMulti}
                    maxFileSize={false}
                    multi={true}
                    accept="application/pdf,image/png,image/jpeg,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    noLabel={true}
                    className={classes.filesUpload}
                />
            </Paper>
        </Grid>
    );
};

export default FilesBlock;
