import React from 'react';
import { Grid, Typography, Paper, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CheckboxGroup, InputField } from 'shared';
import { useStyles } from '../styles';

const PreferredSituation = (props) => {
    const classes = useStyles();
    const { form, currentProfile = {}, expertises = {} } = props;
    const { t } = useTranslation();

    return (
        <Grid item xs={12}>
            <Paper className={classes.paper} elevation={0}>
                <Typography className={classes.heading}>{t('userProfile.titles.preferredSituation')}</Typography>
                <Grid container justify="space-between">
                    <Grid item xs={12} md={6}>
                        <InputField
                            className={classes.input}
                            initialValue={currentProfile.preferredPosition || ''}
                            type="text"
                            label={t('profileForm.preferredPposition')}
                            name="preferredPosition"
                            form={form}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box mt={3} />
                        <Grid container justify="space-between">
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">
                                    {t('userProfile.titles.preferredExpertises-subtitle')}
                                </Typography>
                                {currentProfile.preferredExpertiseList && (
                                    <CheckboxGroup
                                        name="preferredExpertises"
                                        formClasses={classes.customGrid}
                                        itemRootClasses={classes.gridItem}
                                        options={
                                            expertises.expertises
                                                ? expertises.expertises.map((item) => {
                                                      return {
                                                          value: item.id,
                                                          label: item.description
                                                      };
                                                  })
                                                : []
                                        }
                                        initialValue={currentProfile.preferredExpertiseList || []}
                                        form={form}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default PreferredSituation;
