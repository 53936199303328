import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.background.secondary,
        paddingBottom: `${theme.spacing(12)}px`
    },
    grid: {
        width: '100%',
        maxWidth: '1165px',
        margin: '0 auto'
    },
    uploadLabel: {
        ...theme.typography.h6,
        fontWeight: 500,
        fontSize: '1.15em',
        padding: `${theme.spacing(2.25)}px 0 ${theme.spacing(1)}px`,
        [theme.breakpoints.down(580)]: {
            display: 'flex',
            flexFlow: 'column nowrap'
        }
    },
    paper: {
        width: '100%',
        padding: `${theme.spacing(7)}px ${theme.spacing(8)}px ${theme.spacing(8)}px`,
        borderRadius: '25px',

        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3.5)
        },

        [theme.breakpoints.up('md')]: {
            '& .MuiGrid-grid-md-6': {
                maxWidth: 'calc(50% - 50px)',
                flexBasis: 'calc(50% - 50px)',
                '& .MuiGrid-grid-md-6': {
                    maxWidth: 'calc(50% - 15px)',
                    flexBasis: 'calc(50% - 15px)'
                }
            }
        }
    },
    title: {
        textAlign: 'center',
        paddingTop: `${theme.spacing(8)}px`,
        paddingBottom: `${theme.spacing(2)}px`
    },
    label: {
        padding: `${theme.spacing(2.25)}px 0 ${theme.spacing(2)}px`
    },
    heading: {
        ...theme.typography.h6,
        fontWeight: 600,
        paddingBottom: `${theme.spacing(2)}px`,
        borderBottom: '1px solid ' + theme.palette.borderColor.main,
        marginBottom: `${theme.spacing(1)}px`
    },
    input: {
        width: '100%',
        marginLeft: 0,
        '& .MuiInputLabel-shrink': {
            transform: 'none',
            top: '-20px',
            lineHeight: '28px',
            fontWeight: '500',
            fontSize: '18px'
        }
    },
    fakeLabel: {
        width: '100%',
        margin: theme.spacing(2.25, 0),
        fontSize: '1.125rem',
        color: theme.palette.text.secondary,
        fontWeight: 500
    },
    description: {
        paddingBottom: theme.spacing(1)
    },
    remove: {
        color: 'red',
        textAlign: 'right',
        cursor: 'pointer'
    },
    border: {
        borderBottom: `1px solid ${theme.palette.borderColor.primary}`,
        paddingBottom: '20px'
    },
    customGrid: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexFlow: 'column nowrap'
        }
    },
    gridItem: {
        flex: '0 1 33%',
        marginRight: 0,
        paddingRight: theme.spacing(3)
    },
    addBar: {
        ...theme.typography.body1,
        color: theme.palette.primary.main,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: theme.spacing(1.5),
        border: '2px dashed ' + theme.palette.primary.light,
        borderRadius: '3px',
        margin: `${theme.spacing(4)}px 0`,
        cursor: 'pointer',
        userSelect: 'none',
        '&:hover': {
            border: '2px dashed ' + theme.palette.primary.main,
            '& .MuiSvgIcon-root': {
                transform: 'rotate(90deg)'
            }
        },
        '& .MuiSvgIcon-root': {
            marginRight: theme.spacing(1.5),
            transition: 'transform 300ms ease'
        }
    },
    progress: {
        display: 'flex',
        alignItems: 'center'
    },
    progressTitle: {
        fontSize: '1.2em',
        [theme.breakpoints.down(800)]: {
            fontSize: '1rem'
        }
    },
    progressBar: (props) => ({
        height: '10px',
        width: '300px',
        margin: `${theme.spacing(0)}px ${theme.spacing(3)}px`,
        background: theme.palette.background.primary,
        borderRadius: '5px',
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            width: '80px'
        },
        [theme.breakpoints.down(800)]: {
            margin: theme.spacing(0, 1.5)
        },

        '&:after': {
            width: `${props.percentage}%`,
            position: 'absolute',
            borderRadius: '5px',
            top: 0,
            left: 0,
            height: '10px',
            display: 'block',
            content: '" "',
            background: theme.palette.secondary.main
        }
    }),
    progressPercentage: {
        color: theme.palette.text.secondaryLight,
        fontWeight: 500,
        [theme.breakpoints.down(800)]: {
            fontSize: '1rem'
        }
    },
    helperText: {
        padding: theme.spacing(1, 0),
        fontSize: '0.85em',
        color: theme.palette.text.secondary
    },
    filesUpload: {
        marginTop: '10px'
    },
    linkText: {
        fontSize: '12px'
    },
    clearIcon: {
        width: '16px',
        height: '16px',
        marginLeft: theme.spacing(1),
        transition: 'transform 200ms ease',
        cursor: 'pointer',
        '&:hover': {
            transform: 'scale(1.2)',
            color: theme.palette.primary.main
        }
    },
    subTitle: {
        fontSize: '1.125rem'
    },
    emailContent: {
        margin: '20px 0'
    },
    changeEmail: {
        fontSize: '14px',
        textDecoration: 'none',
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.secondary.main
        }
    }
}));
