import React from 'react';
import { Grid, Typography, Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { InputField, AutoCompleteInput, ImageUpload, Switch, RichInputField, Link } from 'shared';
import { useStyles } from '../styles';

import { GET_CITIES } from '../queries';

const PersonalInfo = (props) => {
    const classes = useStyles();
    const { form, currentProfile = {}, currentUser = {}, id } = props;
    const { t } = useTranslation();

    return (
        <Grid item xs={12} id={id}>
            <Paper className={classes.paper} elevation={0}>
                <Typography className={classes.heading}>{t('userProfile.titles.personalInfo')}</Typography>
                <Grid container justify="space-between">
                    <Grid item xs={12} md={6}>
                        <InputField
                            className={classes.input}
                            initialValue={currentProfile.firstName || ''}
                            type="text"
                            label={t('profileForm.firstName')}
                            name="firstName"
                            form={form}
                        />
                        <InputField
                            className={classes.input}
                            initialValue={currentProfile.middleName || ''}
                            type="text"
                            label={t('profileForm.middleName')}
                            name="middleName"
                            form={form}
                        />
                        <InputField
                            className={classes.input}
                            initialValue={currentProfile.lastName || ''}
                            type="text"
                            label={t('profileForm.lastName')}
                            name="lastName"
                            form={form}
                        />
                        <AutoCompleteInput
                            label={t('profileForm.city')}
                            placeholder={t('profileForm.chooseCity')}
                            noOptionsText={t('profileForm.searchCity')}
                            className={classes.input}
                            initialValue={currentProfile.city || {}}
                            name="city"
                            form={form}
                            data={{
                                query: GET_CITIES,
                                response: 'cities'
                            }}
                        />
                        <div className={classes.emailContent}>
                            <Typography variant="h6" className={classes.subTitle}>
                                Email
                                {currentUser.emailIsCorporate && (
                                    <Link className={classes.changeEmail} to="/email-wijzigen">
                                        {' '}
                                        (wijzigen)
                                    </Link>
                                )}
                            </Typography>
                            <Typography variant="body1">{currentUser.email || ''}</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1" className={classes.label}>
                            Profielfoto
                        </Typography>
                        <ImageUpload
                            name="profilePicture"
                            form={form}
                            initialValue={currentProfile.profilePicture || {}}
                        />
                        <Typography variant="subtitle1" className={classes.label}>
                            {t('profileChange.visible')}
                        </Typography>
                        <Switch
                            name="profilePublic"
                            checked={currentProfile.profilePublic || false}
                            label={t('profileChange.visibleToggle')}
                            form={form}
                        />
                        <Typography className={classes.helperText} variant="body2">
                            Zet aan als je zichtbaar wilt zijn voor HR-medewerkers zodat ze je kunnen benaderen voor een
                            kans! Anderen zien je profiel niet
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <RichInputField
                            className={classes.rich}
                            initialValue={currentProfile.biography || ''}
                            name="biography"
                            title="Vertel wat meer over jezelf"
                            subtitle="Vertel hier wat meer over jezelf, zoals je motivatie om in het Kansenportaal te staan. Wat voor een kans zoek je? Waar ben je goed in? Als je je profiel zichtbaar maakt voor HR-medewerkers, is dit de plek om jezelf te laten zien!"
                            form={form}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default PersonalInfo;
