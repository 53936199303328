import React, { useEffect, useState } from 'react';
import { loginUser, setToken, setUserData } from 'store/actions';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import Cookie from 'js-cookie';
import { Background, List, Loader, LoginForm } from 'shared';
import { LOGIN } from 'api';
import jwtDecode from 'jwt-decode';

import Dialog from '@material-ui/core/Dialog';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '1550px',
        width: '100%',
        padding: '0 24px',
        margin: theme.spacing(12) + 'px auto',

        [theme.breakpoints.down('sm')]: {
            margin: 0
        }
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column'
    },
    listLink: {
        fontSize: '16px',
        '& .MuiTypography-root': {
            fontSize: '16px'
        }
    },
    message: {
        border: '1px solid ' + theme.palette.error.main,
        borderRadius: '30px',
        padding: '10px 20px',
        color: theme.palette.error.main,
        maxWidth: '450px',
        width: '100%',
        margin: '0 auto'
    },
    login: {
        maxWidth: '450px',
        width: '100%'
    },
    input: {
        width: '100%',
        marginLeft: 0
    },
    button: {
        width: '100%'
    },
    aside: {
        marginLeft: 'auto',
        position: 'relative',
        padding: `${theme.spacing(6)}px ${theme.spacing(3)}px ${theme.spacing(3)}px 0`,

        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    backgroundTriangle: {
        position: 'absolute',
        zIndex: '-1',
        top: '-1200px',
        left: 'calc(50% - 320px)',
        width: '2000px',
        height: '2000px',

        [theme.breakpoints.down('md')]: {
            left: 'calc(50% - 400px)'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    hiddenImage: {
        display: 'none'
    }
}));

const domains = [
    `${process.env.REACT_APP_MY_FLOW_DOMAIN}/api/cookie?jwt_flow=`,
    `${process.env.REACT_APP_VACANCIES_FLOW_DOMAIN}/api/cookie?jwt_flow=`,
    `${process.env.REACT_APP_TOMORROW_FLOW_DOMAIN}/flowapi/cookie.php?jwt_flow=`
];

const Login = (props) => {
    const { variant = 'default' } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [login, { loading: mutationLoading, error: mutationError }] = useMutation(LOGIN);

    const [token, setTokenState] = useState('');
    const [loginError, setLoginError] = useState('');
    const [open, setOpen] = useState(false);
    const [ssoDone, setSsoDone] = useState([]);
    const listItems = [
        t('register.listItem1'),
        t('register.listItem2'),
        t('register.listItem3'),
        t('register.listItem4'),
        t('register.listItem5')
    ];
    const supportText =
        '<br><br>Lukt inloggen niet of heb je vragen?<br>Mail ons op <a href="mailto:support@flowweb.nl" style="color:#6E3286">support@flowweb.nl</a>.';

    const handleLogin = (username, password) => {
        setLoginError('');
        login({
            variables: {
                email: username,
                password: password
            }
        })
            .then((resp) => {
                if (resp.errors) return;
                if (resp.data) {
                    // ready cookie from that is set from backend
                    const cookie = Cookie.get('jwt_flow');
                    if (cookie) {
                        setTokenState(cookie);
                        // if token exists open en load SSO images
                        setOpen(true);
                    }
                }
            })
            .catch((err) => {
                const errData = JSON.stringify(err);
                const parsedErrors = JSON.parse(errData);
                const statusCode = parsedErrors.networkError && parsedErrors.networkError.statusCode;
                setLoginError(statusCode);
            });
    };

    useEffect(() => {
        if (token) {
            // if token exists open and load SSO images
            setOpen(true);
        }
    }, [token]);

    useEffect(() => {
        if (ssoDone.length >= domains.length) {
            const { state = {} } = location;

            if (token && token !== 'deleted') {
                const decodeToken = jwtDecode(token);

                dispatch(setToken(token));
                dispatch(setUserData(decodeToken.id));
                dispatch(loginUser(true));

                history.push(state.loginParam || '/');
            } else {
                history.push('/login');
            }
            setOpen(false);
        }
    }, [ssoDone, dispatch, token, history, location]);

    useEffect(() => {
        if (token) {
            setOpen(true);
        }
    }, [token]);

    useEffect(() => {
        if (variant === 'logout') {
            setTokenState('deleted');
        }
    }, [variant]);

    const handleImageLoaded = (domain) => () => {
        setSsoDone(domain);
    };

    const handleImageErrored = (domain) => () => {
        setSsoDone(domain);
    };

    const getLoginError = () => {
        switch (loginError) {
            case 401:
                return t('loginForm.notAuthorized');
            case 502:
                return t('loginForm.serverError');
            default:
                t('loginForm.errorMessage');
        }
    };

    return (
        <React.Fragment>
            {/* Set a hidden dialog with images for SSO (the returned image responses contain a cookie which authenticate the user) */}
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                aria-labelledby="simple-dialog-title"
                open={open}
                fullScreen
                className={classes.hiddenImage}
            >
                <div>
                    <Loader />
                    {domains.map((domain) => (
                        <img
                            key={domain}
                            src={`${domain}${token}`}
                            className={classes.hiddenImage}
                            alt="sso"
                            onLoad={handleImageLoaded(domain)}
                            onError={handleImageErrored(domain)}
                        />
                    ))}
                </div>
            </Dialog>
            <Grid className={classes.root} container>
                <Background className={classes.backgroundTriangle} />
                <Grid className={classes.wrapper} item xs={12} md={6}>
                    <LoginForm onChange={handleLogin} />
                    {mutationLoading && <Loader />}
                    {mutationError && (
                        <div className={classes.message}>
                            <span>{loginError ? getLoginError() : t('loginForm.errorMessage')}</span>
                        </div>
                    )}
                </Grid>
                <Grid item xs={4} className={classes.aside}>
                    <List title={t('loginForm.listTitle')} listItems={listItems} />
                    <span dangerouslySetInnerHTML={{ __html: supportText }} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default Login;
