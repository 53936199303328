import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { InputField, DatePicker, AutoCompleteInput } from 'shared';
import { useStyles } from '../styles';
import { GET_CITIES } from '../queries';

const PersonalInfo = (props) => {
    const classes = useStyles();
    const { form, item, idx } = props;
    const { t } = useTranslation();

    //functions
    const handlePeriodChange = (name, val) => {
        if (props.onChange)
            props.onChange({
                name: name,
                value: val,
                idx: idx
            });
    };

    const handleRemove = () => {
        if (props.onRemove) props.onRemove(idx);
    };

    return (
        <Grid key={item.id ? item.id : idx} container justify="space-between">
            <Grid item xs={12}>
                <Typography onClick={handleRemove} className={classes.remove}>
                    Werkervaring verwijderen
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <InputField
                    className={classes.input}
                    type="text"
                    form={form}
                    initialValue={item.position || ''}
                    name={`position-${idx}`}
                    label={t('profileForm.function')}
                    onChange={(val) => handlePeriodChange('position', val)}
                />
                <AutoCompleteInput
                    label={t('profileForm.city')}
                    placeholder={t('profileForm.chooseCity')}
                    noOptionsText={t('profileForm.searchCity')}
                    className={classes.input}
                    initialValue={item.city}
                    name={`city-${idx}`}
                    form={form}
                    data={{
                        query: GET_CITIES,
                        response: 'cities'
                    }}
                    onChange={(e, val) => handlePeriodChange('city', val)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container justify="space-between">
                    <Grid item xs={12}>
                        <InputField
                            className={classes.input}
                            type="text"
                            name={`company-${idx}`}
                            form={form}
                            initialValue={item.company || ''}
                            label={t('profileForm.company')}
                            onChange={(val) => handlePeriodChange('company', val)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DatePicker
                            label="Beginjaar"
                            helper={false}
                            placeholder=""
                            name={`startYear-${idx}`}
                            disablePast={false}
                            disableFuture={false}
                            form={form}
                            initialValue={item.startYear || ''}
                            onChange={(val) => handlePeriodChange('startYear', val)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DatePicker
                            label="Eindjaar"
                            helper={false}
                            placeholder=""
                            disablePast={false}
                            disableFuture={false}
                            form={form}
                            initialValue={item.endYear || ''}
                            name={`endYear-${idx}`}
                            onChange={(val) => handlePeriodChange('endYear', val)}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PersonalInfo;
