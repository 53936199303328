import React from 'react';
import { Grid, Typography, Paper, Box } from '@material-ui/core';
import { CheckboxGroup } from 'shared';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../styles';

const Specialties = (props) => {
    const { data, currentProfile, form } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    if ((data.types && data.types.length < 1) || !currentProfile.id) {
        return '';
    }
    return (
        <Grid item xs={12}>
            <Paper className={classes.paper} elevation={0}>
                <Typography className={classes.heading}>{t('userProfile.titles.specialties')}</Typography>
                <Grid container justify="space-between">
                    <Grid item xs={12}>
                        <Box mt={3}>
                            <Typography variant="subtitle1">{t('userProfile.titles.specialties')}</Typography>
                            {data.types && (
                                <CheckboxGroup
                                    name="specialties"
                                    formClasses={classes.customGrid}
                                    itemRootClasses={classes.gridItem}
                                    options={
                                        data.types
                                            ? data.types.map((item) => {
                                                  return {
                                                      value: item.id,
                                                      label: item.description,
                                                      tooltip: item.explanation
                                                  };
                                              })
                                            : []
                                    }
                                    initialValue={currentProfile.specialties || []}
                                    form={form}
                                />
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default Specialties;
