import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';

import { Grid, Typography, TableRow, TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Table, CtaBlock, Loader, SwipeWrapper } from 'shared';
import { CandidateSearchBar, VacancyRow } from 'shared';
import { formatDate, convertToSlug } from 'helpers';
import { GET_MY_VACANCIES, GET_REGIO_LOGO } from './queries';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '1300px',
        width: '100%',
        margin: '0 auto'
    },
    title: {
        width: '100%',
        textAlign: 'center',
        marginTop: theme.spacing(8),
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2)
        },
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'center'
        }
    },
    searchTitle: {
        margin: theme.spacing(0, 0, 2),
        [theme.breakpoints.up(600)]: {
            margin: 0,
            padding: theme.spacing(3.75, 6.25)
        }
    },
    ctaGrid: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    ctaItem: {
        width: 'calc(33.333% - 20px)',
        flexBasis: 'calc(33.333% - 20px)',
        [theme.breakpoints.down(769)]: {
            flexBasis: '100%',
            marginBottom: theme.spacing(2)
        }
    },
    tableTitle: {
        paddingBottom: theme.spacing(3)
    },
    tableContainer: {
        padding: theme.spacing(6, 9, 15, 9),
        marginBottom: theme.spacing(20),
        overflow: 'auto'
    },
    regionLogo: {
        maxWidth: '35vw',
        margin: theme.spacing(2, 0, 0),
        [theme.breakpoints.up('sm')]: {
            maxWidth: '200px'
        },
        [theme.breakpoints.up('md')]: {
            margin: 0,
            width: '180px',
            position: 'absolute',
            top: '50%',
            right: 0,
            transform: 'translateY(-50%)'
        }
    }
}));

const ctaData = [
    { color: 'quaternary', text: 'Nieuwe kans plaatsen', path: '/hr-kansen/nieuwe-kans' },
    { color: 'primary', text: 'Mijn geplaatste kansen', path: '/hr-kansen' },
    { color: 'tertiary', text: 'Kansen zoeken', path: '/kansen' }
];

const HrDashboard = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const loggedIn = useSelector((state) => state.auth.isLoggedIn);
    const userId = useSelector((state) => state.auth.userId);

    const { data: vacancies = {}, loading } = useQuery(GET_MY_VACANCIES);
    const { data = {} } = useLazyQuery(GET_REGIO_LOGO, {
        variables: {
            id: userId
        }
    });

    const { myVacancies = {} } = vacancies;

    const handleSearch = (obj) => {
        let searchString = `page=0&pageSize=10&profilePublic=true`;
        if (obj.region) {
            searchString += `&region=${obj.region}`;
        }
        if (obj.search) {
            searchString += `&search=${obj.search}`;
        }
        history.push(`/kandidaten?${searchString}`);
    };

    return (
        <Grid container className={classes.root} spacing={8} justify="space-between">
            <Grid item xs={12}>
                <Typography className={classes.title} variant="h2">
                    {t('hrDashboard.title')}

                    {data.user && data.user.corporation && data.user.corporation.region.logo && (
                        <React.Fragment>
                            <img
                                alt={data.user.corporation.region.logo.name}
                                src={data.user.corporation.region.logo.url}
                                className={classes.regionLogo}
                            />
                        </React.Fragment>
                    )}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h5" className={classes.searchTitle}>
                    {t('hrDashboard.searchTitle')}
                </Typography>
                <CandidateSearchBar onChange={handleSearch} loggedIn={loggedIn} />
            </Grid>
            <Grid item xs={12} className={classes.ctaGrid}>
                {ctaData &&
                    ctaData.map((link, idx) => (
                        <CtaBlock
                            key={idx}
                            to={link.path}
                            className={classes.ctaItem}
                            color={link.color}
                            text={link.text}
                            size="small"
                        />
                    ))}
            </Grid>

            <Grid item xs={12} className={classes.tableContainer}>
                <Typography variant="h4" className={classes.tableTitle}>
                    {t('hrDashboard.tableTitle')}
                </Typography>
                {loading ? (
                    <Loader />
                ) : (
                    <SwipeWrapper>
                        <Table
                            head={['Aanmaakdatum', 'Functie', 'Opgeslagen', 'Reacties', 'Publicatiefase', '']}
                            className={classes.table}
                            renderRows={() =>
                                myVacancies.vacancies && myVacancies.vacancies.length > 0 ? (
                                    myVacancies.vacancies.map((vacancy, idx) => (
                                        <VacancyRow
                                            key={idx}
                                            date={formatDate(vacancy.createdOn)}
                                            name={vacancy.title}
                                            saved={vacancy.likedBy.length}
                                            reactions={vacancy.responses.length}
                                            publicationPhase={
                                                vacancy.publicationPeriod.length > 0
                                                    ? vacancy.publicationPeriod[vacancy.publicationPeriod.length - 1]
                                                          .visibility.description
                                                    : ''
                                            }
                                            path={`/kansen/${vacancy.id}/${convertToSlug(vacancy.title)}`}
                                            editPath={`/hr-kansen/bewerken/${vacancy.id}/${convertToSlug(
                                                vacancy.title
                                            )}`}
                                        />
                                    ))
                                ) : (
                                    <TableRow key={0}>
                                        <TableCell colSpan={6}>
                                            <Typography>Geen Kansen gevonden</Typography>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        />
                    </SwipeWrapper>
                )}
            </Grid>
        </Grid>
    );
};

export default HrDashboard;
