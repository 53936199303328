import React from 'react';
import { Grid, Typography, Paper, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { InputField, SelectField, CheckboxGroup } from 'shared';
import { mapToSelect } from '../dist';
import { useStyles } from '../styles';

const PersonalInfo = (props) => {
    const classes = useStyles();
    const {
        form,
        currentProfile = {},
        educationGrades = {},
        expertises = {},
        salaryScales = {},
        workingHoursList = {},
        id
    } = props;
    const { t } = useTranslation();

    return (
        <Grid item xs={12} id={id}>
            <Paper className={classes.paper} elevation={0}>
                <Typography className={classes.heading}>{t('userProfile.titles.situation')}</Typography>
                <Grid container justify="space-between">
                    <Grid item xs={12} md={6}>
                        <InputField
                            className={classes.input}
                            initialValue={currentProfile.position || ''}
                            type="text"
                            label={t('profileForm.position')}
                            name="position"
                            form={form}
                        />
                        <Typography variant="body1" className={classes.fakeLabel}>
                            Regio
                        </Typography>
                        {currentProfile.region && currentProfile.region.description && (
                            <Typography variant="body1" className={classes.description}>
                                {currentProfile.region.description}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container justify="space-between">
                            <Grid item xs={12}>
                                {educationGrades && (
                                    <SelectField
                                        label={t('profileForm.education')}
                                        initialValue={
                                            currentProfile.educationGrade ? currentProfile.educationGrade.id || '' : ''
                                        }
                                        options={mapToSelect(educationGrades.educationGrades, 'description', 'id')}
                                        name="educationGrade"
                                        form={form}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                {salaryScales && salaryScales.types && (
                                    <SelectField
                                        label={t('profileForm.functionScale')}
                                        initialValue={
                                            currentProfile.salaryScale ? currentProfile.salaryScale.id || '' : ''
                                        }
                                        options={mapToSelect(salaryScales.types, 'description', 'id')}
                                        name="salaryScale"
                                        form={form}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                {workingHoursList && workingHoursList.workingHoursList && (
                                    <SelectField
                                        label={t('profileForm.workingHoursPerWeek')}
                                        initialValue={
                                            currentProfile.workingHoursPerWeek
                                                ? currentProfile.workingHoursPerWeek.id || ''
                                                : ''
                                        }
                                        options={mapToSelect(workingHoursList.workingHoursList, 'value', 'id')}
                                        name="workingHoursPerWeek"
                                        form={form}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Box mt={3} />
                <Typography className={classes.heading}>{t('userProfile.titles.expertise')}</Typography>
                <Typography variant="subtitle1">{t('userProfile.titles.expertise-subtitle')}</Typography>
                <div className={classes.customGrid}>
                    {currentProfile.expertiseList && (
                        <CheckboxGroup
                            name="expertiseList"
                            formClasses={classes.customGrid}
                            itemRootClasses={classes.gridItem}
                            options={
                                expertises.expertises
                                    ? expertises.expertises.map((item) => {
                                          return {
                                              value: item.id,
                                              label: item.description
                                          };
                                      })
                                    : []
                            }
                            initialValue={currentProfile.expertiseList || []}
                            form={form}
                        />
                    )}
                </div>
            </Paper>
        </Grid>
    );
};

export default PersonalInfo;
