import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';

import { Grid, Typography, TableRow, TableCell, Tabs, Tab, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import AddIcon from '@material-ui/icons/Add';

import { Table, Button, Loader, SwipeWrapper } from 'shared';
import { VacancyRow } from 'shared';

import { formatDate, convertToSlug } from 'helpers';
import { GET_MY_VACANCIES } from '../queries';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '1300px',
        //minWidth: '968px',
        width: '100%',
        margin: '0 auto',
        paddingBottom: `${theme.spacing(4)}px`,
        [theme.breakpoints.up('sm')]: {
            paddingBottom: `${theme.spacing(22)}px`
        }
    },
    title: {
        width: '100%',
        marginTop: theme.spacing(2),
        [theme.breakpoints.up(768)]: {
            marginTop: `${theme.spacing(7)}px`
        }
    },
    headerBar: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        [theme.breakpoints.down(768)]: {
            alignItems: 'flex-start',
            flexFlow: 'column nowrap'
        }
    },
    headerButton: {
        marginBottom: `${theme.spacing(2)}px`,
        flex: '1 0 auto',
        [theme.breakpoints.down(768)]: {
            margin: theme.spacing(4, 0, 0)
        }
    },
    filterButtons: {
        display: 'flex',
        [theme.breakpoints.down(580)]: {
            paddingBottom: '0 !important'
        },
        [theme.breakpoints.down(768)]: {
            justifyContent: 'center'
        }
    },
    filterButton: {
        fontSize: '1.5rem',
        marginRight: `${theme.spacing(6.25)}px`,
        color: theme.palette.text.secondary,
        padding: `${theme.spacing(1)}px 0`,
        cursor: 'pointer',
        fontWeight: 400,
        [theme.breakpoints.down('sm')]: {
            marginRight: theme.spacing(4),
            fontSize: '1.125rem'
        }
    },
    activeButton: {
        borderBottom: `3px solid ${theme.palette.text.secondary}`
    },
    filterCount: {
        color: theme.palette.text.secondaryLight,
        fontWeight: 300
    },
    table: {
        [theme.breakpoints.down(1025)]: {
            minWidth: '1400px'
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: '900px'
        }
    },
    tableTitle: {
        paddingBottom: `${theme.spacing(3)}px`
    },
    tableContainer: {
        overflow: 'auto',
        paddingTop: `${theme.spacing(6)}px`,
        paddingBottom: `${theme.spacing(15)}px`
    },
    panel: {
        margin: 0,
        padding: 0,
        '& > .MuiBox-root': {
            margin: 0,
            padding: 0
        }
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const HrDashboard = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const { data: vacancies = {}, loading } = useQuery(GET_MY_VACANCIES, {
        variables: {
            filter: {
                draft: false,
                expired: false,
                allVacancies: true
            }
        },
        fetchPolicy: 'no-cache'
    });

    const { data: draftVacancies = {}, loading: loadingDraft } = useQuery(GET_MY_VACANCIES, {
        variables: {
            filter: {
                draft: true,
                allVacancies: true
            }
        },
        fetchPolicy: 'no-cache'
    });

    const { data: expiredVacancies = {}, loading: loadingExpired } = useQuery(GET_MY_VACANCIES, {
        variables: {
            filter: {
                draft: false,
                expired: true,
                allVacancies: true
            }
        },
        fetchPolicy: 'no-cache'
    });

    const myVacancies = vacancies && vacancies.myVacancies ? vacancies.myVacancies : [];
    const myDrafts = draftVacancies && draftVacancies.myVacancies ? draftVacancies.myVacancies : [];
    const expiredList = expiredVacancies && expiredVacancies.myVacancies ? expiredVacancies.myVacancies : [];

    const options = useMemo(
        () => [
            {
                name: `${t('hrMyVacancies.activeVacancies')} (${
                    myVacancies.vacancies ? myVacancies.vacancies.length : 0
                })`,
                id: 1
            },
            {
                name: `${t('hrMyVacancies.inactiveVacancies')} (${myDrafts.vacancies ? myDrafts.vacancies.length : 0})`,
                id: 2
            },
            {
                name: `${t('hrMyVacancies.expiredVacancies')}  (${
                    expiredList.vacancies ? expiredList.vacancies.length : 0
                })`,
                id: 3
            }
        ],
        [expiredList.vacancies, myDrafts.vacancies, myVacancies.vacancies, t]
    );

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    //state hooks
    const [width, setWidth] = React.useState(window.innerWidth);

    //effect hooks
    React.useEffect(() => {
        window.addEventListener('resize', updateWidthAndHeight);
        return () => window.removeEventListener('resize', updateWidthAndHeight);
    });

    React.useEffect(() => {
        if (width) {
            setWidth(width);
        }
    }, [width]);

    //functions
    const updateWidthAndHeight = () => {
        setWidth(window.innerWidth);
    };

    return (
        <React.Fragment>
            <Grid container className={classes.root} spacing={8} justify="space-between">
                <Grid item xs={12} className={classes.headerBar}>
                    <Typography className={classes.title} variant="h1">
                        {t('hrMyVacancies.title')}
                    </Typography>
                    <Button
                        className={classes.headerButton}
                        component={Link}
                        to={'/hr-kansen/nieuwe-kans'}
                        variant="contained"
                        color="secondary"
                        label={t('hrMyVacancies.newVacancy')}
                        iconLeft={() => <AddIcon />}
                    />
                </Grid>

                <Grid item xs={12} className={classes.filterButtons}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="tabs"
                        variant="scrollable"
                        orientation={width <= 767 ? 'vertical' : 'horizontal'}
                    >
                        {options &&
                            options.map((item, idx) => (
                                <Tab key={idx} className={classes.filterButton} label={item.name} {...a11yProps(idx)} />
                            ))}
                    </Tabs>
                </Grid>
                <Grid item xs={12} className={classes.tableContainer}>
                    <TabPanel className={classes.panel} value={value} index={0}>
                        {loading ? (
                            <Loader />
                        ) : (
                            <SwipeWrapper>
                                <Table
                                    head={['Aanmaakdatum', 'Functie', 'Opgeslagen', 'Reacties', 'Publicatiefase', '']}
                                    className={classes.table}
                                    renderRows={() =>
                                        myVacancies.vacancies && myVacancies.vacancies.length > 0 ? (
                                            myVacancies.vacancies.map((vacancy, idx) => (
                                                <VacancyRow
                                                    key={idx}
                                                    date={formatDate(vacancy.createdOn)}
                                                    name={vacancy.title}
                                                    saved={vacancy.likedBy.length}
                                                    reactions={vacancy.responses.length}
                                                    publicationPhase={
                                                        vacancy.visibility ? vacancy.visibility.description : 'Draft'
                                                    }
                                                    path={`/kansen/${vacancy.id}/${convertToSlug(vacancy.title)}`}
                                                    editPath={`/hr-kansen/bewerken/${vacancy.id}/${convertToSlug(
                                                        vacancy.title
                                                    )}`}
                                                />
                                            ))
                                        ) : (
                                            <TableRow key={0}>
                                                <TableCell colSpan={6}>
                                                    <Typography>Geen Kansen gevonden</Typography>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                />
                            </SwipeWrapper>
                        )}
                    </TabPanel>
                    <TabPanel className={classes.panel} value={value} index={1}>
                        {loadingDraft ? (
                            <Loader />
                        ) : (
                            <SwipeWrapper>
                                <Table
                                    head={['Aanmaakdatum', 'Functie', 'Opgeslagen', 'Reacties', 'Publicatiefase', '']}
                                    className={classes.table}
                                    renderRows={() =>
                                        myDrafts.vacancies && myDrafts.vacancies.length > 0 ? (
                                            myDrafts.vacancies.map((vacancy, idx) => (
                                                <VacancyRow
                                                    key={idx}
                                                    date={formatDate(vacancy.createdOn)}
                                                    name={vacancy.title}
                                                    saved={vacancy.likedBy.length}
                                                    reactions={vacancy.responses.length}
                                                    publicationPhase={
                                                        vacancy.visibility ? vacancy.visibility.description : 'Concept'
                                                    }
                                                    path={`/kansen/${vacancy.id}/${convertToSlug(vacancy.title)}`}
                                                    editPath={`/hr-kansen/bewerken/${vacancy.id}/${convertToSlug(
                                                        vacancy.title
                                                    )}`}
                                                />
                                            ))
                                        ) : (
                                            <TableRow key={0}>
                                                <TableCell colSpan={6}>
                                                    <Typography>Geen Kansen gevonden</Typography>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                />
                            </SwipeWrapper>
                        )}
                    </TabPanel>
                    <TabPanel className={classes.panel} value={value} index={2}>
                        {loadingExpired ? (
                            <Loader />
                        ) : (
                            <SwipeWrapper>
                                <Table
                                    head={['Aanmaakdatum', 'Functie', 'Opgeslagen', 'Reacties', 'Publicatiefase', '']}
                                    className={classes.table}
                                    renderRows={() =>
                                        expiredList.vacancies && expiredList.vacancies.length > 0 ? (
                                            expiredList.vacancies.map((vacancy, idx) => (
                                                <VacancyRow
                                                    key={idx}
                                                    date={formatDate(vacancy.createdOn)}
                                                    name={vacancy.title}
                                                    saved={vacancy.likedBy.length}
                                                    reactions={vacancy.responses.length}
                                                    publicationPhase={
                                                        vacancy.visibility ? vacancy.visibility.description : 'Draft'
                                                    }
                                                    path={`/kansen/${vacancy.id}/${convertToSlug(vacancy.title)}`}
                                                    editPath={`/hr-kansen/bewerken/${vacancy.id}/${convertToSlug(
                                                        vacancy.title
                                                    )}`}
                                                />
                                            ))
                                        ) : (
                                            <TableRow key={0}>
                                                <TableCell colSpan={6}>
                                                    <Typography>Geen Kansen gevonden</Typography>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                />
                            </SwipeWrapper>
                        )}
                    </TabPanel>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default HrDashboard;
