import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { Header } from 'shared';
import { isEmpty } from 'helpers';
import { LOGOUT } from 'api';
import { logoutUser } from 'store/actions';
import logo from 'assets/images/logo.svg';
import { ROLE_NAMES } from 'const';

const AppHeader = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [logout] = useMutation(LOGOUT);
    const user = useSelector((state) => state.auth.user);
    const appUser = isEmpty(user) ? undefined : user;

    const isHrUser = useSelector((state) => state.auth.roles.includes(ROLE_NAMES.HR_USER));
    const isCareerAdvisor = useSelector((state) => state.auth.roles.includes(ROLE_NAMES.CAREER_ADVISOR));
    const isDiAdvisor = useSelector((state) => state.auth.roles.includes(ROLE_NAMES.DI_ADVISOR));

    const currentRole = useSelector((state) => state.auth.role);

    const appLinks = useMemo(() => {
        let links;
        if (currentRole === ROLE_NAMES.HR_USER) {
            links = [
                {
                    path: '/hr',
                    linkText: t('menu.dashboard')
                },
                {
                    path: '/kandidaten',
                    linkText: t('menu.users'),
                    children: [
                        {
                            path: '/mijn-kandidaten',
                            linkText: t('menu.myUsers')
                        }
                    ]
                },
                {
                    path: '/mijn-kandidaten',
                    linkText: t('menu.myUsers'),
                    mobile: true
                },
                {
                    path: '/kansen',
                    linkText: t('menu.hrVacancies'),
                    children: [
                        {
                            path: '/hr-kansen',
                            linkText: t('menu.myVacancies')
                        }
                    ]
                },
                {
                    path: '/hr-kansen',
                    linkText: t('menu.myVacancies'),
                    mobile: true
                },
                {
                    path: `${process.env.REACT_APP_MY_FLOW_DOMAIN}?role=${currentRole}`,
                    linkText: t('menu.myFlow'),
                    external: true
                },
                {
                    path: `${process.env.REACT_APP_FLOW_WEB_DOMAIN}/kansenportaal/veelgestelde-vragen-plaatsen-van-kansen`,
                    linkText: t('menu.faq'),
                    external: true
                },
                {
                    path: '/mijn-gegevens',
                    mobile: true,
                    linkText: t('menu.profile')
                },
                {
                    path: '/login',
                    mobile: true,
                    linkText: t('menu.login')
                }
            ];
        } else {
            links = [
                {
                    path: '/',
                    linkText: t('menu.dashboard'),
                    external: false
                },
                {
                    path: '/kansen',
                    linkText: t('menu.vacancies'),
                    external: false
                },
                {
                    path: `${process.env.REACT_APP_FLOW_WEB_DOMAIN}/kansenportaal/veelgestelde-vragen-over-het-kansenportaal`,
                    linkText: t('menu.faq'),
                    external: true
                },
                {
                    path: `${process.env.REACT_APP_MY_FLOW_DOMAIN}?role=${currentRole}`,
                    linkText: t('menu.myFlow'),
                    external: true
                },
                {
                    path: `/mijn-kansen`,
                    linkText: t('menu.myVacancies'),
                    external: false
                },
                {
                    path: '/mijn-gegevens',
                    mobile: true,
                    linkText: t('menu.profile')
                },
                {
                    path: '/login',
                    mobile: true,
                    linkText: t('menu.login')
                }
            ];
        }
        return links;
    }, [currentRole, t]);

    const handleSignOut = () => {
        logout().then((resp) => {
            history.push('/logout');
            dispatch(logoutUser());
        });
    };

    return (
        <Header
            links={appLinks}
            notification={false}
            showHrAccountLink={!isHrUser}
            user={appUser}
            hrUser={isHrUser}
            careerAdvisor={isCareerAdvisor}
            diAdvisor={isDiAdvisor}
            renderLogo={() => (
                <a href={process.env.REACT_APP_FLOW_WEB_DOMAIN} rel="noopener noreferrer" target="_blank">
                    <img src={logo} alt="logo" />
                </a>
            )}
            signOut={handleSignOut}
        />
    );
};

export default AppHeader;
