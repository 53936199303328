import gql from 'graphql-tag';

const GET_USERS = gql`
    query users($pagination: PaginationInput, $filter: UserFilterInput) {
        users(pagination: $pagination, filter: $filter) {
            users {
                id
                email
                profile {
                    firstName
                    lastName
                    biography
                    city {
                        id
                        name
                    }
                    workingHoursPerWeek {
                        id
                        value
                    }
                    profilePicture {
                        url
                        name
                    }
                    region {
                        id
                        description
                    }
                }
            }
            count
        }
    }
`;

// Used to fetch the likes of the current user
export const GET_MY_USERS = gql`
    query currentUser {
        currentUser {
            id
            likes {
                id
                corporation {
                    id
                    name
                }
                profile {
                    fullName
                    region {
                        id
                        description
                    }
                    city {
                        id
                        name
                    }
                    educationGrade {
                        id
                        description
                    }
                    salaryScale {
                        id
                        description
                    }
                    workingHoursPerWeek {
                        id
                        value
                    }
                }
            }
        }
    }
`;

const GET_USER = gql`
    query getUser($id: Long!) {
        user(id: $id) {
            id
            profile {
                biography
                completeness {
                    done
                    name
                }
                dateOfBirth
                educationGrade {
                    id
                    description
                }
                firstName
                lastName
                profilePublic
                salaryScale {
                    id
                    description
                }
                position
                profilePicture {
                    id
                    name
                    thumbnailUrl
                    description
                    url
                }
                city {
                    id
                    name
                }
                educations {
                    id
                    direction
                    endYear
                    institution
                    startYear
                    title
                }
                workExperience {
                    id
                    city {
                        id
                        name
                        province
                    }
                    company
                    endYear
                    startYear
                    position
                }
                specialties {
                    id
                    description
                    explanation
                }
                skillList {
                    id
                    description
                }
                expertiseList {
                    id
                    description
                }
                preferredExpertiseList {
                    id
                    description
                }
                preferredPosition
                region {
                    id
                    description
                }
                workingHoursPerWeek {
                    id
                    value
                }
            }
            corporation {
                id
                domain
                name
            }
        }
    }
`;

const GET_VACANCY_CANDIDATES = gql`
    query responses($vacancyId: Long!) {
        responses(vacancyId: $vacancyId) {
            cv {
                id
                name
                url
            }
            videoURL
            attachments {
                id
                name
                url
            }
            user {
                id
                profile {
                    firstName
                    lastName
                    city {
                        id
                        name
                    }
                    workingHoursPerWeek {
                        id
                        value
                    }
                }
            }
            vacancy {
                id
                title
            }
            motivation
        }
    }
`;

const GET_WORKING_HOURS = gql`
    query workingHours {
        workingHoursList {
            workingHoursList {
                id
                value
            }
            count
        }
    }
`;

const GET_EXPERTISES = gql`
    query expertises {
        expertises(pagination: { page: 0, pageSize: 50 }) {
            count
            expertises {
                description
                id
            }
        }
    }
`;

const GET_REGIONS = gql`
    query regions {
        regions {
            regions {
                id
                description
            }
            count
        }
    }
`;

const GET_EDUCATION = gql`
    query education {
        educationGrades(pagination: { page: 0, pageSize: 50 }) {
            educationGrades {
                description
                id
            }
        }
    }
`;

const GET_PROVINCES = gql`
    query provinces {
        provinces
    }
`;

const GET_VACANCY_TYPES = gql`
    query vacancyTypes {
        paginatedVacancyTypes(pagination: { page: 0, pageSize: 50 }) {
            types {
                description
                id
                isAvailableAsSpecialty
            }
        }
    }
`;

const RESPOND_TO_USER = gql`
    mutation contactWithUser($input: ContactUserInput!) {
        contactWithUser(input: $input) {
            userId
        }
    }
`;

export {
    GET_EXPERTISES,
    GET_PROVINCES,
    GET_REGIONS,
    GET_VACANCY_TYPES,
    GET_USER,
    GET_USERS,
    GET_VACANCY_CANDIDATES,
    GET_WORKING_HOURS,
    RESPOND_TO_USER,
    GET_EDUCATION
};
