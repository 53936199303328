import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core';
import { useUploadFile } from 'hooks';
import { Button, FileUpload, MultipleSelectField, InputField, Checkbox } from 'shared';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    fileUpload: {
        marginTop: '20px'
    },
    input: {
        width: '100%',
        margin: '30px 0'
    }
}));

const ApplyDialog = (props) => {
    const { open, user = {} } = props;

    const classes = useStyles();
    const [uploadFile, fileData] = useUploadFile();
    const { t } = useTranslation();

    //state hooks
    const [documents, setDocuments] = useState([]);
    const [sendCv, setSendCv] = useState(false);
    const [cvId, setCvId] = useState(null);
    const [youtubeId, setYoutubeId] = useState(null);
    const [careerProfile, setCareerProfile] = useState({});

    //effect hooks
    useEffect(() => {
        if (user) {
            const { careerProfile: temp = {} } = user;
            setCareerProfile(temp);
        }
    }, [user]);

    useEffect(() => {
        if (!fileData.loading && fileData.data) {
            const { uploadFile = {} } = fileData.data;
            if (uploadFile.asset && uploadFile.asset.id) {
                setCvId(uploadFile.asset.id);
            }
        }
    }, [fileData.loading, fileData.data]);

    const handleClose = () => {
        if (props.onClose) props.onClose();
    };

    const handleOnBlur = (event) => {
        if (props.onBlur) props.onBlur(event);
    };

    const handleClick = () => {
        if (props.onClick)
            props.onClick({
                documents,
                youtubeId,
                cvId,
                sendCv
            });
    };

    const handleCV = (file) => {
        if (file[0] && file[0].name) {
            uploadFile(file[0]);
        }
        if (props.handleCV) props.handleCV(file);
    };

    const handleChange = (value) => {
        const arr = [];
        careerProfile.documents.forEach((i) => {
            if (value.some((val) => val === i.name)) {
                arr.push(i.id);
            }
        });
        setDocuments(arr);
    };

    const handleCVClear = () => {
        setCvId(null);
    };

    const handleCheckbox = (data) => {
        setSendCv(data.value);
    };

    const handleYoutube = (value) => {
        setYoutubeId(value);
    };

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{t('vacancyDetail.responseLabel')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('vacancyDetail.responseMotiation')}</DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="motivation"
                    label={t('vacancyDetail.responseLabel')}
                    type="email"
                    fullWidth
                    multiline
                    rows="8"
                    onBlur={handleOnBlur}
                />
                {careerProfile.cv && careerProfile.cv.id ? (
                    <Checkbox onChange={handleCheckbox} label="CV meesturen?" name="sendCv" />
                ) : (
                    <FileUpload
                        label="CV uploaden"
                        acceptLabel="PDF, Word of Excel-document"
                        onSucces={handleCV}
                        maxFileSize={false}
                        name="cv"
                        className={classes.fileUpload}
                        accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        onClear={handleCVClear}
                        multi
                    />
                )}
                {careerProfile.documents && careerProfile.documents.length ? (
                    <MultipleSelectField
                        label="Bestanden meesturen"
                        helper={false}
                        placeholder="Selecteer bestanden"
                        options={careerProfile.documents.map((i) => ({ label: i.name, value: i.name }))}
                        name="files"
                        onChange={handleChange}
                    />
                ) : (
                    ''
                )}
                <InputField
                    name="videoURL"
                    label={t('vacancyDetail.youtubeurl')}
                    type="text"
                    className={classes.input}
                    onChange={handleYoutube}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" label="Annuleren" />
                <Button
                    onClick={handleClick}
                    variant="contained"
                    label={t('vacancyDetail.reactButton')}
                    color="primary"
                />
            </DialogActions>
        </Dialog>
    );
};

export default ApplyDialog;
