import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box } from '@material-ui/core';

const Unauthorized = () => {
    const { t } = useTranslation();
    return (
        <Box mt={10} mb={10}>
            <Grid container justify="center">
                <Grid item xs={6}>
                    <Typography variant="h3">{t('Unauthorized.title')}</Typography>
                    <Typography>{t('Unauthorized.text')}</Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Unauthorized;
