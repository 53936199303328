import GalanoMediumWoff from './fonts/GalanoGrotesque-AltMedium.woff';
import GalanoBoldWoff from './fonts/GalanoGrotesque-AltBold.woff';
import GalanoLightWoff from './fonts/GalanoGrotesque-AltLight.woff';
// import GalanoWoff from './fonts/GalanoGrotesque-AltRegular.woff';
import GalanoWoff from './fonts/GalanoGrotesque-AltRegular.woff';

export const galano = {
    fontFamily: 'Galanogrotesque',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 'normal',
    src: `
        local('Galanogrotesque'),
        local('Galanogrotesque-altregular'),
        url(${GalanoWoff}) format('woff')
  `
};

export const galanoMedium = {
    fontFamily: 'Galanogrotesque',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 500,
    src: `
        local('Galanogrotesque'),
        local('Galanogrotesque-altmedium'),
        url(${GalanoMediumWoff}) format('woff')
  `
};

export const galanoBold = {
    fontFamily: 'Galanogrotesque',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 700,
    src: `
        local('Galanogrotesque'),
        local('GalanoGrotesque-altbold'),
        url(${GalanoBoldWoff}) format('woff')
  `
};

export const galanoLight = {
    fontFamily: 'Galanogrotesque',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 300,
    src: `
        local('Galanogrotesque'),
        local('GalanoGrotesque-altlight'),
        url(${GalanoLightWoff}) format('woff')
  `
};

export const galanoRegular = {};
