import React, { useEffect, useState, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
    PersonalInfo,
    SituationInfo,
    SkillsInfo,
    EducationBlock,
    WorkBlock,
    FilesBlock,
    PreferredSituation,
    Specialties
} from './parts';
import { Add, ClearTwoTone } from '@material-ui/icons';
import { Grid, Typography, Paper, Box } from '@material-ui/core';
import { useStyles } from './styles';
import { Breadcrumbs, Button, Link } from 'shared';
import { SubMenu, LoaderModal } from 'shared';
import { useForm } from 'hooks';
import { useSnackbar } from 'notistack';
import { setUserData } from 'store/actions';

import {
    GET_CURRENT_USER,
    SAVE_USER,
    GET_EDUCATION_GRADES,
    GET_EXPERTISES,
    GET_SKILLS,
    GET_SALARY_SCALES,
    SAVE_PROFILE_PICTURE,
    REMOVE_PROFILE_PICTURE,
    GET_WORKING_HOURS,
    GET_SPECIALTIES
} from './queries';

const BreadcrumbPath = [
    {
        title: 'Jouw profiel',
        link: '/mijn-gegevens'
    }
];

let timer;

const UserProfile = (props) => {
    //state hooks
    const [percentage, setPercentage] = useState(0);
    const [currentUser, setCurrentUser] = useState({});
    const [currentProfile, setCurrentProfile] = useState({});
    const [currentCareerProfile, setCurrentCareerProfile] = useState({});
    const dispatch = useDispatch();

    //reducers
    const reducer = (state, action) => {
        if (action.type === 'add') {
            return [...state, action.data];
        }
        if (action.type === 'remove') {
            let arr = [...state];
            arr.splice(action.data, 1);
            return [...arr];
        }
        let temp = [...state];
        temp[action.idx] = {
            ...temp[action.idx],
            [action.name]: action.value
        };
        return temp;
    };
    const [educationArr, setEducationArr] = useReducer(reducer, []);
    const [workArr, setWorkArr] = useReducer(reducer, []);
    const [formSettings, setFormSettings] = useReducer(
        (state, action) => {
            if (action.type === 'add') return { ...state, ...action.data };
            if (action.type === 'remove') {
                const { [action.data]: data, ...otherState } = state;
                return { ...otherState };
            }
            return state;
        },
        { firstName: { required: true } }
    );

    //custom hooks
    const [form, submitForm] = useForm(formSettings);
    const { enqueueSnackbar } = useSnackbar();

    //other hooks
    const classes = useStyles({ percentage });
    const { t } = useTranslation();
    const { external = false } = useParams();

    //mutations
    const [saveUser, { loading: mutationLoading }] = useMutation(SAVE_USER);
    const [saveProfilePicture, { loading: profilePictureMutationLoading }] = useMutation(SAVE_PROFILE_PICTURE);
    const [removeProfilePicture] = useMutation(REMOVE_PROFILE_PICTURE);

    //Queries
    const { data: educationData = {} } = useQuery(GET_EDUCATION_GRADES);
    const { data: expertiseData = {} } = useQuery(GET_EXPERTISES);
    const { data: skillsData = {} } = useQuery(GET_SKILLS);
    const { data: hoursData = {} } = useQuery(GET_WORKING_HOURS);
    const { data: salaryScaleData = {} } = useQuery(GET_SALARY_SCALES);
    const { data: specialtyData = {} } = useQuery(GET_SPECIALTIES);
    const {
        loading,
        data = {},
        refetch
    } = useQuery(GET_CURRENT_USER, {
        fetchPolicy: 'no-cache'
    });

    //QueryData
    const { educationGrades = [] } = educationData;
    const { expertises = [] } = expertiseData;
    const { skills = [] } = skillsData;
    const { salaryScales = [] } = salaryScaleData;
    const { workingHoursList = [] } = hoursData;
    const { specialtyVacancyTypes = [] } = specialtyData;

    //effect hooks
    useEffect(() => {
        if (!loading && data && data.currentUser) {
            const { profile = {}, careerProfile = {} } = data.currentUser;
            if (profile.completeness) {
                handlePercentage(profile);
            }

            setCurrentUser(data.currentUser);
            setCurrentProfile(profile);
            setCurrentCareerProfile(careerProfile);
            if (profile.educations && profile.educations.length) {
                profile.educations.forEach((item, idx) => {
                    const { id, ...otherProps } = item;
                    setFormSettings({
                        type: 'add',
                        data: {
                            [`institution-${idx}`]: { required: true }
                        }
                    });
                    setEducationArr({
                        type: 'add',
                        data: {
                            ...otherProps
                        }
                    });
                });
            }
            if (profile.workExperience && profile.workExperience.length) {
                profile.workExperience.forEach((item, idx) => {
                    const { id, ...otherProps } = item;
                    setFormSettings({
                        type: 'add',
                        data: {
                            [`position-${idx}`]: { required: true }
                        }
                    });
                    setWorkArr({
                        type: 'add',
                        data: {
                            ...otherProps
                        }
                    });
                });
            }
        }

        let searchLocation = window.location.search;
        let locationParams = new URLSearchParams(searchLocation);

        if (locationParams && !loading) {
            setTimeout(() => {
                let scrollTo = locationParams.get('data-scroll-to');
                let anchorEl = document.getElementById(scrollTo);
                if (anchorEl) {
                    let anchorElOffset = anchorEl.offsetTop;
                    let offsetHeight = anchorElOffset - (window.outerWidth >= 960 ? 164 : 80);

                    window.scrollTo({ top: offsetHeight, behavior: 'smooth' });
                }
            }, 1);
        }
    }, [loading, data]);

    //functions

    const handlePercentage = (profile) => {
        const doneItems = profile.completeness.filter((item) => item.done).length;
        setPercentage(25 * doneItems);
    };

    const handleSaveUser = () => {
        submitForm().then((resp) => {
            if (currentUser.id) {
                if (resp.profilePicture) {
                    saveProfilePicture({
                        variables: {
                            file: resp.profilePicture
                        },
                        context: {
                            hasUpload: true
                        }
                    });
                } else if (resp.profilePicture === null) {
                    removeProfilePicture();
                }

                // return;
                saveUser({
                    variables: {
                        input: {
                            id: currentUser.id,
                            careerProfile: {
                                cvId: resp.cvId || null
                            },
                            profile: {
                                firstName: resp.firstName,
                                lastName: resp.lastName,
                                middleName: resp.middleName,
                                city: resp.city ? resp.city.id || -1 : -1,
                                position: resp.position,
                                profilePublic: resp.profilePublic,
                                educationGrade: resp.educationGrade || null,
                                salaryScale: resp.salaryScale || null,
                                workingHoursPerWeek: resp.workingHoursPerWeek || null,
                                educations: educationArr || [],
                                workExperience: workArr
                                    ? workArr.map((i) => {
                                          const city = i.city ? i.city.id || null : null;
                                          return {
                                              ...i,
                                              city: city
                                          };
                                      })
                                    : [],
                                skillList: resp.skillList,
                                specialties: resp.specialties,
                                expertiseList: resp.expertiseList,
                                biography: resp.biography,
                                preferredExpertiseList: resp.preferredExpertises,
                                preferredSalaryScale: resp.preferredSalaryScale || null,
                                preferredPosition: resp.preferredPosition
                            }
                        }
                    }
                })
                    .then((r) => {
                        if (!r.errors) {
                            if (
                                r.data &&
                                r.data.updateUser &&
                                r.data.updateUser.user &&
                                r.data.updateUser.user.profile
                            ) {
                                handlePercentage(r.data.updateUser.user.profile);
                            }
                            dispatch(setUserData(currentUser.id));
                            enqueueSnackbar({
                                variant: 'success',
                                message: t('profileChange.saved')
                            });
                        } else {
                            enqueueSnackbar({
                                variant: 'error',
                                message: t('profileChange.errorMessage')
                            });
                        }
                    })
                    .catch((e) => {
                        enqueueSnackbar({
                            variant: 'error',
                            message: t('profileChange.errorMessage')
                        });
                    });
            }
        });
    };

    const addEducationRow = () => {
        setFormSettings({
            type: 'add',
            data: {
                [`institution-${educationArr.length}`]: { required: true }
            }
        });

        setEducationArr({
            type: 'add',
            data: {
                institution: ''
            }
        });
    };

    const removeEducationRow = (data) => {
        setFormSettings({
            type: 'remove',
            data: `institution-${data}`
        });
        form.onRemove(`institution-${data}`);

        setEducationArr({
            type: 'remove',
            data: data
        });
    };

    const handleEducation = (data) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            setEducationArr(data);
        }, 200);
    };

    const handleWork = (data) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            setWorkArr(data);
        }, 200);
    };

    const removeWorkRow = (data) => {
        setFormSettings({
            type: 'remove',
            data: `institution-${data}`
        });
        form.onRemove(`institution-${data}`);
        setWorkArr({
            type: 'remove',
            data: data
        });
    };

    const addWorkRow = () => {
        setFormSettings({
            type: 'add',
            data: {
                [`position-${workArr.length}`]: { required: true }
            }
        });

        setWorkArr({
            type: 'add',
            data: {
                position: ''
            }
        });
    };

    return (
        <React.Fragment>
            <SubMenu
                renderLeft={() => (
                    <React.Fragment>
                        <div className={classes.progress}>
                            <Typography className={classes.progressTitle} variant="h5">
                                {t('profileChange.title')}
                            </Typography>
                            <div className={classes.progressBar} />
                            <Typography className={classes.progressPercentage} variant="body1">
                                {percentage} % {t('profileChange.voltooid')}
                            </Typography>
                        </div>
                    </React.Fragment>
                )}
                renderRight={() => (
                    <React.Fragment>
                        <Button
                            variant="outlined"
                            label={t('profileChange.cancel')}
                            component={Link}
                            to={'/mijn-gegevens'}
                            iconLeft={() => <ClearTwoTone />}
                        />
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleSaveUser}
                            label={t('profileChange.save')}
                        />
                        {external && (
                            <Button
                                variant="contained"
                                color="secondary"
                                label="Terug naar Mijn Flow"
                                component={Link}
                                to={`${process.env.REACT_APP_MY_FLOW_DOMAIN}`}
                            />
                        )}
                    </React.Fragment>
                )}
            />
            <Breadcrumbs path={BreadcrumbPath} />
            <div className={classes.root}>
                <Grid className={classes.grid} container spacing={4} justify="center" alignItems="center">
                    <Grid item xs={12}>
                        <Typography className={classes.title} variant="h2">
                            {t('profileChange.title')}
                        </Typography>
                    </Grid>

                    <PersonalInfo currentProfile={currentProfile} currentUser={currentUser} form={form} id="personal" />

                    <PreferredSituation currentProfile={currentProfile} expertises={expertises} form={form} />

                    <SituationInfo
                        currentProfile={currentProfile}
                        currentUser={currentUser}
                        form={form}
                        salaryScales={salaryScales}
                        workingHoursList={workingHoursList}
                        educationGrades={educationGrades || {}}
                        expertises={expertises || {}}
                        id="situation"
                    />

                    <Specialties currentProfile={currentProfile} data={specialtyVacancyTypes} form={form} />

                    <FilesBlock
                        currentCareerProfile={currentCareerProfile}
                        form={form}
                        currentUser={currentUser}
                        refetch={refetch}
                    />

                    <SkillsInfo currentProfile={currentProfile} skills={skills || {}} form={form} id="skills" />

                    <Grid item xs={12} id="workExperience">
                        <Paper className={classes.paper} elevation={0}>
                            <Typography className={classes.heading}>{t('userProfile.titles.courses')}</Typography>
                            {educationArr.length > 0 &&
                                educationArr.map((item, idx) => (
                                    <EducationBlock
                                        item={item}
                                        form={form}
                                        onChange={handleEducation}
                                        onRemove={removeEducationRow}
                                        idx={idx}
                                        key={idx}
                                    />
                                ))}
                            <Grid container>
                                <Typography onClick={addEducationRow} className={classes.addBar}>
                                    <Add /> {t('profileForm.addEducaton')}
                                </Typography>
                            </Grid>
                            <Box mt={3} />
                            <Typography className={classes.heading}>{t('userProfile.titles.experience')}</Typography>
                            {workArr.length > 0 &&
                                workArr.map((item, idx) => (
                                    <WorkBlock
                                        item={item}
                                        form={form}
                                        onChange={handleWork}
                                        onRemove={removeWorkRow}
                                        idx={idx}
                                        key={idx}
                                    />
                                ))}
                            <Grid container>
                                <Typography onClick={addWorkRow} className={classes.addBar}>
                                    <Add />
                                    <span>{t('profileForm.addWorkExperience')}</span>
                                </Typography>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            <LoaderModal loading={loading || mutationLoading || profilePictureMutationLoading} />
        </React.Fragment>
    );
};

export default UserProfile;
