import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { CandidateItem } from 'shared';
import { Breadcrumbs } from 'shared';
import { convertToSlug } from 'helpers';
import { GET_VACANCY_CANDIDATES } from '../queries';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(10)
    },
    item: {
        maxWidth: '853px'
    }
}));

const RespondedCandidates = () => {
    const classes = useStyles();

    const { id } = useParams();
    const { data = {} } = useQuery(GET_VACANCY_CANDIDATES, {
        variables: {
            vacancyId: id
        }
    });
    const { responses = [] } = data;

    const BreadcrumbPath = useMemo(() => {
        if (responses[0]) {
            return [
                {
                    title: 'Kansen',
                    link: '/kansen'
                },
                {
                    title: responses[0].vacancy.title,
                    link: `/kansen/${responses[0].vacancy.id}/${convertToSlug(responses[0].vacancy.title)}`
                },
                {
                    title: 'Kandidaten',
                    link: `/kansen/${responses[0].vacancy.id}/${convertToSlug(responses[0].vacancy.title)}/kandidaten`
                }
            ];
        }
    }, [responses]);

    return (
        <React.Fragment>
            <Breadcrumbs path={BreadcrumbPath} />
            <Grid className={classes.root} container justify="center" direction="column" alignContent="center">
                <Grid className={classes.item} item xs={6}>
                    {responses[0] && <Typography variant="h2">{responses[0].vacancy.title}</Typography>}
                    {responses.length > 0 ? (
                        <Typography variant="body1">
                            {responses.length > 1
                                ? `${responses.length} Kandidaten gevonden`
                                : `${responses.length} Kandidaat gevonden`}
                        </Typography>
                    ) : (
                        <Typography variant="body1">Nog geen reacties op deze kans</Typography>
                    )}
                </Grid>
                <Grid className={classes.item} item xs={6}>
                    {responses &&
                        responses.map((user) => (
                            <CandidateItem
                                format={false}
                                key={user.user.id}
                                title={`${user.user.profile.firstName} ${user.user.profile.lastName}`}
                                link={`/kandidaten/${user.user.id}/${user.user.profile.firstName} ${user.user.profile.firstName}`}
                                place={user.user.profile.city ? user.user.profile.city.name : ''}
                                hours={
                                    user.user.profile.workingHoursPerWeek
                                        ? user.user.profile.workingHoursPerWeek.value || 'Onbekend'
                                        : 'Onbekend'
                                }
                                // approached={candidate.approached}
                                introduction={user.motivation}
                                data={user}
                            />
                        ))}
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default RespondedCandidates;
