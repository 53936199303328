import React from 'react';
import { Grid, Typography, Paper, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CheckboxGroup } from 'shared';
import { useStyles } from '../styles';

const PersonalInfo = (props) => {
    const classes = useStyles();
    const { form, currentProfile = {}, skills = {}, id } = props;
    const { t } = useTranslation();

    return (
        <Grid item xs={12} id={id}>
            <Paper className={classes.paper} elevation={0}>
                <Typography className={classes.heading}>{t('userProfile.titles.skills')}</Typography>
                <Typography variant="subtitle1">{t('userProfile.titles.skills-subtitle')}</Typography>
                <Box mt={2} />
                <div className={classes.customGrid}>
                    {currentProfile.skillList && (
                        <CheckboxGroup
                            name="skillList"
                            formClasses={classes.customGrid}
                            itemRootClasses={classes.gridItem}
                            options={
                                skills.skills
                                    ? skills.skills.map((item) => {
                                          return {
                                              value: item.id,
                                              label: item.description
                                          };
                                      })
                                    : []
                            }
                            initialValue={currentProfile.skillList || []}
                            form={form}
                        />
                    )}
                </div>
            </Paper>
        </Grid>
    );
};

export default PersonalInfo;
