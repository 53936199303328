import React from 'react';
import classNames from 'classnames';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AccessTime, DeleteOutline, ArrowRightAlt } from '@material-ui/icons';

import { TableCell, TableRow } from 'shared';

const useStyles = makeStyles((theme) => ({
    center: {
        display: 'inline-flex',
        alignItems: 'center',
        color: theme.palette.text.secondaryLight
    },
    title: {
        color: theme.palette.text.secondary
    },
    timeWrap: {
        minWidth: '100px'
    },
    time: {
        marginRight: theme.spacing(1)
    },
    nameCell: {
        width: '450px'
    },
    actionButton: {
        border: '1px solid ' + theme.palette.text.secondaryLight,
        borderRadius: '50%',
        color: theme.palette.text.secondary,
        background: theme.palette.common.white,
        width: '50px',
        height: '50px',
        padding: '10px',
        marginLeft: '20px',
        cursor: 'pointer',
        transition: 'all 300ms ease',
        '&:hover': {
            border: '1px solid ' + theme.palette.text.secondary,
            background: theme.palette.text.secondary,
            color: theme.palette.common.white
        }
    }
}));

const VacancyRow = (props) => {
    const { vacancy, onClick, onDelete } = props;
    const classes = useStyles();
    return (
        <TableRow className={classNames(classes.row, { [classes.link]: onClick })}>
            <TableCell className={classes.nameCell} align="left">
                <Typography onClick={() => onClick(vacancy.id)} className={classes.title}>
                    {vacancy.title}
                </Typography>
            </TableCell>
            <TableCell align="left">
                <Typography className={classNames(classes.center, classes.timeWrap)}>
                    {vacancy.workingHoursAWeek && <AccessTime className={classes.time} />}
                    {vacancy.workingHoursAWeek ? vacancy.workingHoursAWeek.value || '' : ''}
                </Typography>
            </TableCell>
            <TableCell align="left">
                <Typography className={classes.center}>{vacancy.city && vacancy.city.name}</Typography>
            </TableCell>
            <TableCell align="right">
                <div className={classes.center}>
                    <DeleteOutline onClick={() => onDelete(vacancy.id)} className={classes.actionButton} />
                    <ArrowRightAlt
                        onClick={() => onClick(vacancy.id, vacancy.title)}
                        className={classes.actionButton}
                    />
                </div>
            </TableCell>
        </TableRow>
    );
};

export default VacancyRow;
