import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { InputField, DatePicker } from 'shared';
import { useStyles } from '../styles';

const EducationBlock = (props) => {
    const classes = useStyles();
    const { form, item, idx } = props;
    const { t } = useTranslation();

    //functions
    const handlePeriodChange = (name, val) => {
        if (props.onChange)
            props.onChange({
                name: name,
                value: val,
                idx: idx
            });
    };

    const handleRemove = () => {
        if (props.onRemove) props.onRemove(idx);
    };

    return (
        <Grid container justify="space-between" className={classes.border}>
            <Grid item xs={12}>
                <Typography onClick={handleRemove} className={classes.remove}>
                    Opleiding verwijderen
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <InputField
                    className={classes.input}
                    type="text"
                    form={form}
                    initialValue={item.institution || ''}
                    name={`institution-${idx}`}
                    label={t('profileForm.educationalInstitution')}
                    onChange={(val) => handlePeriodChange('institution', val)}
                />
                <InputField
                    className={classes.input}
                    type="text"
                    initialValue={item.direction || ''}
                    name={`direction-${idx}`}
                    label={t('profileForm.discipline')}
                    optional
                    form={form}
                    onChange={(val) => handlePeriodChange('direction', val)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container justify="space-between">
                    <Grid item xs={12}>
                        <InputField
                            className={classes.input}
                            type="text"
                            initialValue={item.direction || ''}
                            name={`title-${idx}`}
                            onChange={(val) => handlePeriodChange('title', val)}
                            form={form}
                            label={t('profileForm.title')}
                            optional
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DatePicker
                            label="Beginjaar"
                            helper={false}
                            name={`startYear-${idx}`}
                            initialValue={item.startYear}
                            disablePast={false}
                            disableFuture={false}
                            onChange={(val) => handlePeriodChange('startYear', val)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DatePicker
                            label="Eindjaar"
                            placeholder=""
                            helper={false}
                            name={`endYear-${idx}`}
                            initialValue={item.endYear}
                            disablePast={false}
                            disableFuture={false}
                            onChange={(val) => handlePeriodChange('endYear', val)}
                            error={false}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default EducationBlock;
