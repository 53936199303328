import { Block, Button } from 'shared';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    subtitle: {
        padding: `${theme.spacing(2.5)}px ${theme.spacing(0)}px ${theme.spacing(3)}px`,
        width: '100%'
    },
    text: {
        paddingBottom: `${theme.spacing(2)}px`
    },
    button: {
        display: 'inline-block',
        width: 'auto',
        flex: '0 0 auto',
        marginRight: `${theme.spacing(2)}px`,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            textAlign: 'center',
            '& + $button': {
                marginTop: theme.spacing(1)
            }
        }
    }
}));

const ProfileInfo = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Block variant="white" title={t('profileInfo.title')} row="row">
            <Typography variant="h6" className={classes.subtitle}>
                {t('profileInfo.subTitle')}
            </Typography>
            <Typography variant="body1" className={classes.text}>
                {t('profileInfo.text')}
            </Typography>
            <Button
                component={Link}
                to={'/register'}
                variant="contained"
                color="secondary"
                label={t('profileInfo.subscribe')}
                fullWidth={false}
                className={classes.button}
            />
            <Button
                href={`${process.env.REACT_APP_FLOW_WEB_DOMAIN}/kansenportaal`}
                target="_blank"
                rel="noopener noreferrer"
                variant="outlined"
                className={classes.button}
                label={t('profileInfo.info')}
            />
        </Block>
    );
};

export default ProfileInfo;
