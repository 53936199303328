import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { Loader, List } from 'shared';
import { RegisterForm } from 'shared';
import { makeStyles } from '@material-ui/styles';
import { CREATE_USER } from './queries';
import Cookie from 'js-cookie';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '1400px',
        width: '100%',
        padding: `0 ${theme.spacing(3)}px`,
        margin: `${theme.spacing(12)}px auto`,

        [theme.breakpoints.down('sm')]: {
            margin: 0,
            padding: theme.spacing(6, 3)
        }
    },
    errorBlock: {
        background: theme.palette.background.primary,
        padding: theme.spacing(2),
        borderRadius: '6px',
        margin: `${theme.spacing(2)}px auto`,
        maxWidth: '450px',
        width: '100%'
    },
    aside: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    }
}));

const Register = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [succesMessage, setSuccesMessage] = useState(false);
    const [errors, setErrors] = useState([]);
    const [createUser, { loading: mutationLoading }] = useMutation(CREATE_USER);
    const listItems = [
        t('register.listItem1'),
        t('register.listItem2'),
        t('register.listItem3'),
        t('register.listItem4'),
        t('register.listItem5')
    ];

    const handleRegistration = (data) => {
        setErrors([]);
        setSuccesMessage(false);
        const { city, email, firstName, lastName, middleName } = data;
        createUser({
            variables: {
                city,
                email,
                firstName,
                lastName,
                middleName
            }
        })
            .then((resp) => {
                if (resp.errors) {
                    let errors = resp.errors;
                    setErrors(errors);
                }
                if (resp.data) {
                    setSuccesMessage(true);
                }
            })
            .catch((error) => {
                setErrors([{ message: t('error.serverError') }]);
            });
    };

    useEffect(() => {
        Cookie.set('popup', true, { expires: 365 });
    }, []);

    return (
        <Grid className={classes.root} container>
            <Grid className={classes.wrapper} item xs={12} md={8}>
                <RegisterForm
                    title={t('register.pageTitle')}
                    onSubmit={handleRegistration}
                    subTitle={t('register.pageSubtitle')}
                />
                {mutationLoading && <Loader />}
            </Grid>
            <Grid item xs={4} className={classes.aside}>
                <List title={t('register.listHeading')} listItems={listItems} />
                {succesMessage && (
                    <div className={classes.errorBlock}>
                        <Typography variant="h5">Gelukt</Typography>
                        <Typography>Je ontvangt van ons een mail om je account te activeren!</Typography>
                    </div>
                )}

                {errors && errors.length > 0 && (
                    <div className={classes.errorBlock}>
                        <Typography variant="h5">Let op:</Typography>
                        {errors.map((error, idx) => {
                            var tString;
                            try {
                                tString = error.message.split(':')[1].replace(/\s+/g, '');
                            } catch (error) {
                                tString =
                                    'Onbekende fout opgetreden. Het kan zijn dat je account al bestaat, vraag dan een nieuw wachtwoord aan!';
                            }
                            return <Typography key={idx}>{t(tString)}</Typography>;
                        })}
                    </div>
                )}
            </Grid>
        </Grid>
    );
};

export default Register;
