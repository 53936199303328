import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { TableCell, TableRow } from 'shared';
import { convertToSlug } from 'helpers';

const useStyles = makeStyles((theme) => ({
    center: {
        display: 'inline-flex',
        alignItems: 'center',
        color: theme.palette.text.secondaryLight
    },
    title: {
        ...theme.typography.body1,
        color: theme.palette.text.secondary,
        textDecoration: 'none'
    },
    timeWrap: {
        minWidth: '100px'
    },
    time: {
        marginRight: theme.spacing(1)
    },
    nameCell: {
        width: '450px',
        verticalAlign: 'initial'
    },
    actionButton: {
        border: '1px solid ' + theme.palette.text.secondaryLight,
        borderRadius: '50%',
        color: theme.palette.text.secondary,
        background: theme.palette.common.white,
        width: '50px',
        height: '50px',
        padding: '10px',
        marginLeft: '20px',
        cursor: 'pointer',
        transition: 'all 300ms ease',
        '&:hover': {
            border: '1px solid ' + theme.palette.text.secondary,
            background: theme.palette.text.secondary,
            color: theme.palette.common.white
        }
    },
    motivation: {
        maxHeight: '100px',
        height: '100px',
        overflow: 'hidden',
        '&.open': {
            maxHeight: 'auto !important',
            height: 'auto'
        }
    }
}));

const RespondedRow = (props) => {
    const { vacancy } = props;
    const classes = useStyles();

    return (
        <TableRow className={classes.row}>
            <TableCell className={classes.nameCell} align="left">
                <Link
                    to={`/kansen/${vacancy.id.vacancyId}/${convertToSlug(vacancy.vacancy.title)}`}
                    className={classes.title}
                >
                    {vacancy.vacancy.title}
                </Link>
            </TableCell>
            <TableCell align="left">
                <Typography className={classes.center}>{vacancy.motivation}</Typography>
            </TableCell>
        </TableRow>
    );
};

export default RespondedRow;
