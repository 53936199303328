import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        flexFlow: 'row wrap',
        maxWidth: '1245px',
        margin: '0 auto'
    },
    inner: {
        flex: '1 1 auto',
        display: 'inherit',
        flexFlow: 'inherit',
        margin: `-${theme.spacing(4.25) / 2}px`,
        [theme.breakpoints.down(767)]: {
            margin: `-${theme.spacing(4.25) / 4}px`
        }
    },
    title: {
        flex: '1 1 100%',
        margin: theme.spacing(0, 0, 2),
        [theme.breakpoints.up('sm')]: {
            margin: `0 0 ${theme.spacing(4.5)}px`
        }
    },
    ctaBlock: {
        margin: `${theme.spacing(4.25) / 2}px`,
        position: 'relative',
        flexGrow: 0,
        flexShrink: 1,
        flexBasis: `calc(100% / 4 - ${theme.spacing(4.25)}px)`,
        minHeight: '232px',
        backgroundColor: theme.palette.background.secondary,
        borderRadius: theme.palette.shape.borderRadius,
        color: 'currentColor',
        textDecoration: 'none',
        borderBottom: '0px solid transparent',
        transition: 'border-bottom 200ms ease',
        [theme.breakpoints.up('md')]: {
            '&:hover': {
                borderBottom: `6px solid ${theme.palette.background.primary}`,
                '& $ctaBlockText': {
                    opacity: 1,
                    visibility: 'visible',
                    '& + $ctaBlockTitle': {
                        opacity: 0,
                        visibility: 'hidden'
                    }
                }
            }
        },
        [theme.breakpoints.down('md')]: {
            flexBasis: `calc(100% / 2 - ${theme.spacing(4.25)}px)`
        },
        [theme.breakpoints.down(767)]: {
            flexBasis: `calc(100% / 1 - ${theme.spacing(2)}px)`,
            margin: theme.spacing(1)
        }
    },
    small: {
        minHeight: '100px'
    },
    ctaBlockInner: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-end',
        padding: `${theme.spacing(5.75)}px ${theme.spacing(3.75)}px`,
        height: '100%'
    },
    ctaBlockTitle: {
        position: 'absolute',
        left: theme.spacing(3.75),
        right: theme.spacing(3.75),
        bottom: theme.spacing(5.75),
        transition: 'opacity 200ms ease, visibility 200ms ease'
    },
    ctaBlockText: {
        opacity: 0,
        visibility: 'hidden',
        transition: 'opacity 200ms ease, visibility 200ms ease'
    },
    primary: {
        backgroundColor: theme.palette.background.secondary,
        [theme.breakpoints.up('md')]: {
            '&:hover': {
                borderColor: theme.palette.background.secondaryContrast
            }
        }
    },
    secondary: {
        backgroundColor: theme.palette.background.blue,
        [theme.breakpoints.up('md')]: {
            '&:hover': {
                borderColor: theme.palette.background.blueContrast
            }
        }
    },
    tertiary: {
        backgroundColor: theme.palette.background.pink,
        [theme.breakpoints.up('md')]: {
            '&:hover': {
                borderColor: theme.palette.background.pinkContrast
            }
        }
    },
    quaternary: {
        backgroundColor: theme.palette.background.yellow,
        [theme.breakpoints.up('md')]: {
            '&:hover': {
                borderColor: theme.palette.background.yellowContrast
            }
        }
    }
}));

const CtaBlocks = (props) => {
    const classes = useStyles();
    const {
        data: { title, size, items }
    } = props;

    return (
        <Grid className={classes.root}>
            {title && (
                <Typography variant="h3" className={classes.title}>
                    {title}
                </Typography>
            )}

            <div className={classes.inner}>
                {items.map((item, idx) => {
                    if (item.external) {
                        return (
                            <a
                                id={'click-id-cta-' + item.id}
                                href={item.href}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classNames(
                                    classes.ctaBlock,
                                    classes[item.color || 'primary'],
                                    classes[size]
                                )}
                                key={idx}
                            >
                                <div className={classes.ctaBlockInner}>
                                    {item.hoverText && (
                                        <Typography variant="body2" className={classes.ctaBlockText}>
                                            {item.hoverText}
                                        </Typography>
                                    )}
                                    <Typography variant="h6" className={classes.ctaBlockTitle}>
                                        {item.text}
                                    </Typography>
                                </div>
                            </a>
                        );
                    } else if (item.href) {
                        return (
                            <Link
                                to={item.href}
                                className={classNames(
                                    classes.ctaBlock,
                                    classes[item.color || 'primary'],
                                    classes[size]
                                )}
                                key={idx}
                            >
                                <div className={classes.ctaBlockInner}>
                                    {item.hoverText && (
                                        <Typography variant="body2" className={classes.ctaBlockText}>
                                            {item.hoverText}
                                        </Typography>
                                    )}
                                    <Typography variant="h6" className={classes.ctaBlockTitle}>
                                        {item.text}
                                    </Typography>
                                </div>
                            </Link>
                        );
                    } else {
                        return (
                            <div
                                className={classNames(classes.ctaBlock, classes[item.color || 'primary'])}
                                key={idx}
                                size={size}
                            >
                                <div className={classes.ctaBlockInner}>
                                    {item.hoverText && (
                                        <Typography variant="body2" className={classes.ctaBlockText}>
                                            {item.hoverText}
                                        </Typography>
                                    )}
                                    <Typography variant="h6" className={classes.ctaBlockTitle}>
                                        {item.text}
                                    </Typography>
                                </div>
                            </div>
                        );
                    }
                })}
            </div>
        </Grid>
    );
};

export default CtaBlocks;
