import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SelectField, DatePicker } from 'shared';
import { useStyles } from '../styles';

const PublicationBlock = (props) => {
    const classes = useStyles();
    const { form, item, idx, vacancyVisibilities = {} } = props;
    const { t } = useTranslation();

    //functions
    const handlePeriodChange = (name, val) => {
        if (props.onChange)
            props.onChange({
                name: name,
                value: val,
                idx: idx
            });
    };

    const mapToSelect = (array, label, value) => {
        if (!array) return;

        let newArray = array.map((item) => {
            return {
                label: item[label],
                value: item[value]
            };
        });

        return newArray;
    };

    const handleRemove = () => {
        if (props.onRemove) props.onRemove(idx);
    };

    return (
        <Grid key={idx} container justify="space-between">
            {idx > 0 && (
                <Grid item xs={12}>
                    <Typography onClick={handleRemove} className={classes.remove}>
                        {t('newVacancy.publicationPossibilities')}
                    </Typography>
                </Grid>
            )}
            <Grid item xs={12} md={3}>
                <DatePicker
                    label={t('newVacancy.publicationDate')}
                    placeholder=""
                    helper={false}
                    name={`startDate-${idx}`}
                    disablePast={false}
                    onChange={(val) => handlePeriodChange('startDate', val)}
                    initialValue={item.startDate}
                    form={form}
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <DatePicker
                    label={t('newVacancy.endDate')}
                    placeholder=""
                    helper={false}
                    name={`endDate-${idx}`}
                    disablePast={false}
                    onChange={(val) => handlePeriodChange('endDate', val)}
                    initialValue={item.endDate}
                    form={form}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                {vacancyVisibilities.vacancyVisibilities && (
                    <SelectField
                        label={t('newVacancy.visibility')}
                        placeholder="Zichtbaarheid"
                        helper={false}
                        options={mapToSelect(vacancyVisibilities.vacancyVisibilities, 'description', 'id')}
                        name={`visibility-${idx}`}
                        onChange={(val) => handlePeriodChange('visibility', val)}
                        initialValue={item.visibility || ''}
                        form={form}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default PublicationBlock;
